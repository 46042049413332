import { Routes } from '@angular/router';
import { samlLoginGuard } from '../../core/guards/saml-login.guard';
import { loggedOutGuard } from '../../core/guards/logged-out-guard';
import { loggedInGuard } from '../../core/guards/logged-in.guard';
import { extractQueryParamsGuard } from '../../core/guards/marpp-access.guard';

export const authRoutes: Routes = [
    {
        path: 'signup',
        loadComponent: () => import('./signup/signup.component').then((c) => c.SignupComponent),
        title: $localize`Setup your Finboot account`,
    },
    {
        path: 'signin',
        canActivate: [samlLoginGuard(), loggedOutGuard()],
        loadComponent: () => import('./login/login.component').then((c) => c.LoginComponent),
        title: $localize`Login with your Finboot account`,
    },
    {
        path: 'forgot-password',
        canActivate: [loggedOutGuard()],
        loadComponent: () =>
            import('./forgot-password/forgot-password.component').then(
                (c) => c.ForgotPasswordComponent,
            ),
        title: $localize`Password Recovery`,
    },
    {
        path: 'restore-password-response',
        canActivate: [loggedOutGuard()],
        loadComponent: () =>
            import('./change-password/change-password.component').then(
                (c) => c.ChangePasswordComponent,
            ),
        title: $localize`Change your Password`,
    },
    {
        path: 'access',
        canActivate: [loggedInGuard(), extractQueryParamsGuard()],
        loadComponent: () =>
            import('./marpp-access/marpp-access.component').then((m) => m.MarppAccessComponent),
        title: $localize`Grant permissions to App`,
    },
    {
        path: 'logout',
        canActivate: [loggedInGuard()],
        loadComponent: () => import('./logout/logout.component').then((c) => c.LogoutComponent),
    },
    { path: '**', redirectTo: 'signin' },
];
