import { Routes } from '@angular/router';
import { HealthCheckComponent } from './pages/healthcheck/health-check.component';
import { authRoutes } from './pages/auth/auth.routes';
import { loggedInGuard } from './core/guards/logged-in.guard';

const mainLayout = () =>
    import('./layouts/main-layout/main-layout.component').then((m) => m.MainLayoutComponent);

export const routes: Routes = [
    {
        path: 'auth',
        loadComponent: mainLayout,
        children: authRoutes,
    },
    {
        path: 'invitations',
        loadComponent: mainLayout,
        children: [
            {
                path: 'respond',
                loadComponent: () =>
                    import('./pages/invitation-respond/invitation-respond.component').then(
                        (m) => m.InvitationRespondComponent,
                    ),
                title: 'Respond to Invitation',
            },
        ],
    },
    {
        path: 'user',
        loadComponent: mainLayout,
        children: [
            {
                path: 'init',
                loadComponent: () =>
                    import('./pages/user-init/user-init.component').then(
                        (m) => m.UserInitComponent,
                    ),
            },
        ],
        title: $localize`Setup your Finboot account`,
    },
    {
        path: 'account',
        loadComponent: mainLayout,
        children: [
            {
                path: '',
                canActivate: [loggedInGuard()],
                loadComponent: () =>
                    import('./pages/account/account.component').then((m) => m.AccountComponent),
            },
        ],
        title: $localize`Your Finboot account details`,
    },
    {
        path: 'status/health',
        component: HealthCheckComponent,
    },
    { path: '**', redirectTo: '/auth/signin' },
];
